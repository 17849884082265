var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hidden lg:block w-300 border-0 border-r border-solid border-borderInput"},[_c('div',{staticClass:"pt-3 px-0"},[_c('div',{staticClass:"flex flex-col gap-y-8 items-center justify-center"},[_c('avatar-upload',{attrs:{"param-file":_vm.user.image,"active-upload":true,"reSize":true},on:{"update:paramFile":function($event){return _vm.$set(_vm.user, "image", $event)},"update:param-file":function($event){return _vm.$set(_vm.user, "image", $event)}}}),_c('div',{staticClass:"flex flex-col w-full gap-y-32"},[_c('ul',{staticClass:"flex flex-col gap-y-8 w-full px-10"},[_c('li',{staticClass:"flex items-center gap-x-3 text-base cursor-pointer",class:_vm.page === 1
                ? 'font-EffraM text-rosDok-ultra'
                : 'font-EffraR text-black',on:{"click":function($event){_vm.currentPage = 1}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","fill":"none","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2.5","d":"M16.667 17.5v-1.667c0-.884-.352-1.732-.977-2.357-.625-.625-1.473-.976-2.357-.976H6.667c-.884 0-1.732.351-2.357.976-.626.625-.977 1.473-.977 2.357V17.5M10 9.167c1.841 0 3.333-1.493 3.333-3.334 0-1.84-1.492-3.333-3.333-3.333-1.84 0-3.333 1.492-3.333 3.333 0 1.841 1.492 3.334 3.333 3.334z"}})]),_vm._v(" Mon compte ")]),_c('li',{staticClass:"flex items-center gap-x-3 text-base cursor-pointer",class:_vm.page === 2
                ? 'font-EffraM text-rosDok-ultra'
                : 'font-EffraR text-black',on:{"click":function($event){_vm.currentPage = 2}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"21","fill":"none","viewBox":"0 0 20 21"}},[_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15.833 3.5H4.167c-.92 0-1.667.784-1.667 1.75V17.5c0 .966.746 1.75 1.667 1.75h11.666c.92 0 1.667-.784 1.667-1.75V5.25c0-.966-.746-1.75-1.667-1.75zM13.333 1.75v3.5M6.667 1.75v3.5M2.5 8.75h15"}})]),_vm._v(" Mes Rendez-vous ")]),_c('li',{staticClass:"flex items-center gap-x-3 text-base cursor-pointer",class:_vm.page === 3
                ? 'font-EffraM text-rosDok-ultra'
                : 'font-EffraR text-black',on:{"click":function($event){_vm.currentPage = 3}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"21","fill":"none","viewBox":"0 0 20 21"}},[_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M3.333 3.5h13.334c.916 0 1.666.787 1.666 1.75v10.5c0 .962-.75 1.75-1.666 1.75H3.333c-.916 0-1.666-.788-1.666-1.75V5.25c0-.963.75-1.75 1.666-1.75z"}}),_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M18.333 5.25L10 11.375 1.667 5.25"}})]),_vm._v(" Email et mot de passe ")]),_c('li',{staticClass:"flex items-center gap-x-3 text-base cursor-pointer",class:_vm.page === 4
                ? 'font-EffraM text-rosDok-ultra'
                : 'font-EffraR text-black',on:{"click":function($event){_vm.currentPage = 4}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","fill":"none","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M11.442 17.5c-.147.253-.357.462-.61.608-.253.146-.54.222-.832.222-.292 0-.579-.076-.832-.222-.253-.146-.463-.355-.61-.608m9.775-3.333H1.667c.663 0 1.299-.264 1.768-.732.468-.47.732-1.105.732-1.768V7.5c0-1.547.614-3.03 1.708-4.125C6.97 2.281 8.453 1.667 10 1.667c1.547 0 3.03.614 4.125 1.708 1.094 1.094 1.708 2.578 1.708 4.125v4.167c0 .663.264 1.299.733 1.768.469.468 1.104.732 1.767.732z"}})]),_vm._v(" Notifications ")])]),_c('div',{staticClass:"flex flex-col gap-y-8"},[_c('ul',{staticClass:"flex flex-col gap-y-8 w-full  px-10"},[_c('li',{staticClass:"flex items-center gap-x-3 text-base cursor-pointer",class:_vm.page === 6
                  ? 'font-EffraM text-rosDok-ultra'
                  : 'font-EffraR text-black',on:{"click":function($event){_vm.currentPage = 6}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"21","fill":"none","viewBox":"0 0 20 21"}},[_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M7.5 18.375H4.167c-.442 0-.866-.184-1.179-.513-.312-.328-.488-.773-.488-1.237V4.375c0-.464.176-.91.488-1.237.313-.329.737-.513 1.179-.513H7.5M13.333 14.875L17.5 10.5l-4.167-4.375M17.5 10.5h-10"}})]),_vm._v(" Se deconnecter ")])]),_c('dok-download-app',{attrs:{"other-text":true,"otherClasses":"p-0 m-0 border-0 rounded-bl-lg"}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }