<template>
  <div
    class="hidden lg:block w-300 border-0 border-r border-solid border-borderInput"
  >
    <div class="pt-3 px-0">
      <!--- Upload Avatar--->
      <div class="flex flex-col gap-y-8 items-center justify-center">
        <avatar-upload
          :param-file.sync="user.image"
          :active-upload="true"
          :reSize="true"
        ></avatar-upload>

        <!-- Links -->

        <!-- Active Code : #FD5B68 | #000 -->
        <div class="flex flex-col w-full gap-y-32">
          <ul class="flex flex-col gap-y-8 w-full px-10">
            <li
              class="flex items-center gap-x-3 text-base cursor-pointer"
              :class="
                page === 1
                  ? 'font-EffraM text-rosDok-ultra'
                  : 'font-EffraR text-black'
              "
              @click="currentPage = 1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.5"
                  d="M16.667 17.5v-1.667c0-.884-.352-1.732-.977-2.357-.625-.625-1.473-.976-2.357-.976H6.667c-.884 0-1.732.351-2.357.976-.626.625-.977 1.473-.977 2.357V17.5M10 9.167c1.841 0 3.333-1.493 3.333-3.334 0-1.84-1.492-3.333-3.333-3.333-1.84 0-3.333 1.492-3.333 3.333 0 1.841 1.492 3.334 3.333 3.334z"
                />
              </svg>
              Mon compte
            </li>
            <li
              class="flex items-center gap-x-3 text-base cursor-pointer"
              :class="
                page === 2
                  ? 'font-EffraM text-rosDok-ultra'
                  : 'font-EffraR text-black'
              "
              @click="currentPage = 2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                fill="none"
                viewBox="0 0 20 21"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.833 3.5H4.167c-.92 0-1.667.784-1.667 1.75V17.5c0 .966.746 1.75 1.667 1.75h11.666c.92 0 1.667-.784 1.667-1.75V5.25c0-.966-.746-1.75-1.667-1.75zM13.333 1.75v3.5M6.667 1.75v3.5M2.5 8.75h15"
                />
              </svg>
              Mes Rendez-vous
            </li>
            <li
              class="flex items-center gap-x-3 text-base cursor-pointer"
              :class="
                page === 3
                  ? 'font-EffraM text-rosDok-ultra'
                  : 'font-EffraR text-black'
              "
              @click="currentPage = 3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                fill="none"
                viewBox="0 0 20 21"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3.333 3.5h13.334c.916 0 1.666.787 1.666 1.75v10.5c0 .962-.75 1.75-1.666 1.75H3.333c-.916 0-1.666-.788-1.666-1.75V5.25c0-.963.75-1.75 1.666-1.75z"
                />
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18.333 5.25L10 11.375 1.667 5.25"
                />
              </svg>
              Email et mot de passe
            </li>
            <li
              class="flex items-center gap-x-3 text-base cursor-pointer"
              :class="
                page === 4
                  ? 'font-EffraM text-rosDok-ultra'
                  : 'font-EffraR text-black'
              "
              @click="currentPage = 4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11.442 17.5c-.147.253-.357.462-.61.608-.253.146-.54.222-.832.222-.292 0-.579-.076-.832-.222-.253-.146-.463-.355-.61-.608m9.775-3.333H1.667c.663 0 1.299-.264 1.768-.732.468-.47.732-1.105.732-1.768V7.5c0-1.547.614-3.03 1.708-4.125C6.97 2.281 8.453 1.667 10 1.667c1.547 0 3.03.614 4.125 1.708 1.094 1.094 1.708 2.578 1.708 4.125v4.167c0 .663.264 1.299.733 1.768.469.468 1.104.732 1.767.732z"
                />
              </svg>
              Notifications
            </li>
            <!-- <li
              class="flex items-center gap-x-3 text-base cursor-pointer"
              :class="
                page === 5
                  ? 'font-EffraM text-rosDok-ultra'
                  : 'font-EffraR text-black'
              "
              @click="currentPage = 5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                fill="none"
                viewBox="0 0 20 21"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11.667 1.75H5c-.442 0-.866.184-1.179.513-.312.328-.488.773-.488 1.237v14c0 .464.176.91.488 1.237.313.329.737.513 1.179.513h10c.442 0 .866-.184 1.178-.513.313-.328.489-.773.489-1.237V7l-5-5.25z"
                />
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11.667 1.75V7h5M13.333 11.375H6.667M13.333 14.875H6.667M8.333 7.875H6.667"
                />
              </svg>
              Carte Nationale
            </li> -->
          </ul>

          <div class="flex flex-col gap-y-8">
            <ul class="flex flex-col gap-y-8 w-full  px-10">
              <li
                class="flex items-center gap-x-3 text-base cursor-pointer"
                :class="
                  page === 6
                    ? 'font-EffraM text-rosDok-ultra'
                    : 'font-EffraR text-black'
                "
                @click="currentPage = 6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  fill="none"
                  viewBox="0 0 20 21"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7.5 18.375H4.167c-.442 0-.866-.184-1.179-.513-.312-.328-.488-.773-.488-1.237V4.375c0-.464.176-.91.488-1.237.313-.329.737-.513 1.179-.513H7.5M13.333 14.875L17.5 10.5l-4.167-4.375M17.5 10.5h-10"
                  />
                </svg>
                Se deconnecter
              </li>
            </ul>

            <dok-download-app
              :other-text="true"
              otherClasses="p-0 m-0 border-0 rounded-bl-lg"
            ></dok-download-app>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const avatarUpload = () => import("@/views/global-components/uploadAvatar.vue");
const dokDownloadApp = () =>
  import("@/layouts/home/layouts/navbar/layouts/downloadApp.vue");
import { EventBus } from "@/event-bus";

export default {
  components: { avatarUpload, dokDownloadApp },
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(number) {
        EventBus.$emit("Change_Page_Number_My_Account_Patient", number);
      }
    }
  },
  data() {
    return {
      user: {}
    };
  }
};
</script>
